export const elements = {
  htmlTag: document.querySelector('html'),
  body: document.querySelector('body'),
  text: document.querySelectorAll('p,a,h1,h2,h3,h4,h5,h6'),
  headings: document.querySelectorAll('h1,h2,h3,h4,h5,h6'),
  lightSwitch: document.querySelector('.light-switch'),
  toggleSwitch: document.querySelector('.light-switch-toggle'),
  switchText: document.querySelector('.light-switch-toggle-ui span'),
  toggleSwitchUILeft: document.querySelector('.light-switch-toggle-ui--left'),
  toggleSwitchUIRight: document.querySelector('.light-switch-toggle-ui--right'),
  menuList: document.querySelector('.menu__list'),
  menuItem: document.querySelectorAll('.menu__item'),
  menuItems: document.querySelectorAll('.menu__link'),
  gallery: document.querySelector('.gallery'),
  gallerySet: document.querySelector('.gallery-set'),
  upArrow: document.querySelector('footer .upArrow'),
  toTop: document.querySelector('footer span'),
  navToggle: document.getElementById('navigation-toggle'),
  menuPopup: document.querySelector('.menu-full'),
  navBtn: document.querySelector('.navigation__button'),
  menuFullLinks: document.querySelectorAll('.menu-full__list .menu__link'),
  imgs: document.querySelectorAll('.image-set img'),
  modal: document.querySelector('.modal'),
  modalImg: document.querySelector('.modal img'),
  modalImgContainer: document.querySelector('.image-container'),
  modalCheck: document.querySelector('.modal__checkbox'),
  closeModal: document.querySelector('.modal__button'),
  prevEl: document.querySelector('.modal .prev'),
  nextEl: document.querySelector('.modal .next'),
  mouse: document.querySelector('.circle'),
};
